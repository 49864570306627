import { useMemo } from "react";
import { useMessages } from "../hooks/MessagesContext";
// @ts-ignore
import { TagCloud } from "react-tagcloud";
import { Box } from "@mui/material";

const brownCorpus = require("./lexicon.json");

// const words = [
//   {
//     value: "told",
//     count: 64,
//   },
//   {
//     value: "mistake",
//     count: 11,
//   },
//   {
//     value: "thought",
//     count: 16,
//   },
//   {
//     value: "bad",
//     count: 17,
//   },
// ];
export const WordCloud = ({ messages }: { messages: string[]}) => {
  const words = useMemo(() => {
    const count: { [word: string]: number } = {};

    messages.forEach((msg) => {
      const msgWords = msg.split(" ");
      msgWords.forEach((word) => {
        const tags = brownCorpus[word];
        if (tags) {
          if (
            ![
              "NN",
              "NNS",
              "VB",
              "VBD",
              "VBG",
              "VBN",
              "VBZ",
              "JJ",
              "JJR",
              "JJS",
              "JJT",
              "RB",
              "RBR",
            ].includes(tags[0])
          ) {}
          else if (['for', 'a', 'so', 'as'].includes(word)) {}
          else if (count[word]) count[word] += 1;
          else count[word] = 1;
        }
      });
    });

    return Object.entries(count).map(([k, v]) => {
      return { value: k, count: v };
    }).sort((a, b) => b.count - a.count).slice(0, 59);
  }, [messages]);
  
  return (
    <Box>
      <TagCloud minSize={12} maxSize={60} tags={words} />
    </Box>
  );
};
